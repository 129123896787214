import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ResponseCalender} from "../API/response/calender/calender";
import {useNavigate, useParams} from "react-router-dom";
import React, {useRef, useState} from "react";
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
// @ts-ignore
import {Notification} from "create-notifications/notfication";
import {ApiURL} from "../../../../@WUM/core/component/const/ApiURL";
import SearchJSX from "../../../../@WUM/core/component/const/SearchJSX";
import {IntlMessageValue} from "../../../../@WUM/core/component/const/IntlMessage";
import {useSelector} from "react-redux";

const TodolistOverview = (props: { value: any }) => {
    const { page, deleteID } = useParams<{ page: string; deleteID: string }>()
    const Store = useSelector((state: any) => state)
    let limit: any
    const [searchValue, setSearchValue] = useState('')

    if (typeof page === 'string') {
        limit = parseFloat(page) * 10 - 10
    }
    const response = ResponseCalender(limit, searchValue)
    const submit = (e:any,id:any,parameter:any) => {
        e.preventDefault();
        let data = {
            parameter:parameter,
            value: e.target.value
        };
        let raw = JSON.stringify(data);
        let root = ApiURL();
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw,
        };
        fetch(root+"update/parameter/"+id+"/", requestOptions)
            .then(response => response.text())
            .then(result => Notification({rewrite:true,landingpages:'#',type:'success', mode:'classic', title:{text:'Success'}, text:{text: 'Saved successfully'}, insert:'center right',duration:4}))
            .catch(error => Notification({rewrite:true,landingpages:'#',type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Sorry, an error has occurred'}, insert:'center right',duration:4}));

    }

    const destination = 'todoList_history'
    const namespace_search = 'todoList_searchValue'
    const SearchConfig = [
        {
            namespace: namespace_search,
            destination: destination,
            input: [
                {
                    id: 'search_name',
                    translation: 'Kunde',
                    param: 'name',
                    method: 'like'
                }
            ],
            button: [
                'common.search',
                'common.del.search',
                'Neue News erstellen'
            ]
        }
    ]

    return(<>
        <div id="wrapperContent">
            <SearchJSX
                setSearchValue={setSearchValue}
                Store={Store}
                noNewButton={true}
                SearchConfig={SearchConfig}
            />
            <BuildTodo ressponse={response} submit={submit} />
        </div>
    </>

    )

}

export default TodolistOverview;

const BuildTodo = (props:{ressponse:any,submit:any}) =>{

    let Cards = [];


    const SelectField = (nameSpace:any,state:any,id:any) => {

        return (
            <>
                    <Form.Select name={'value'} aria-label="Default select example" value={state} onChange={(e) => props.submit(e,id,nameSpace)}>
                        <option value="0">Warte</option>
                        <option value="1">OK</option>
                    </Form.Select>
            </>
        )

    }

    for(let x=0;x<props.ressponse.length;x++) {
        let openTodos = 0;
        let closeTodos = 0;
        if(props.ressponse[x].germanCompany !== 1) { openTodos++;} else {closeTodos++}
        if(props.ressponse[x].germanComanyDocuments !== 1) { openTodos++;} else {closeTodos++}
        if(props.ressponse[x].foreignCompany !== 1) { openTodos++;} else {closeTodos++}
        if(props.ressponse[x].foreignCompanyDocuments !== 1) { openTodos++;} else {closeTodos++}

        if(props.ressponse[x].sroPrivacy !== 1) { openTodos++;} else {closeTodos++}
        if(props.ressponse[x].sroServiceContract !== 1) { openTodos++;} else {closeTodos++}
        if(props.ressponse[x].sroDataForm !== 1) { openTodos++;} else {closeTodos++}
        if(props.ressponse[x].sroNotar !== 1) { openTodos++;} else {closeTodos++}
        //if(props.ressponse[x].sroRequestDocuments !== 1) { openTodos++;} else {closeTodos++}
        if(props.ressponse[x].sroRecevieDocuments !== 1) { openTodos++;} else {closeTodos++}
        if(props.ressponse[x].sroRequestPayment !== 1) { openTodos++;} else {closeTodos++}
        if(props.ressponse[x].sroRecevieDocuments !== 1) { openTodos++;} else {closeTodos++}

        if(props.ressponse[x].ewivServiceContract !== 1) { openTodos++;} else {closeTodos++}
        if(props.ressponse[x].ewivNotar !== 1) { openTodos++;} else {closeTodos++}
        //if(props.ressponse[x].ewivRequestDocuments !== 1) { openTodos++;} else {closeTodos++}
        if(props.ressponse[x].ewivReceiveDocuments !== 1) { openTodos++;} else {closeTodos++}
        if(props.ressponse[x].ewivRequestPayment !== 1) { openTodos++;} else {closeTodos++}
        if(props.ressponse[x].ewivReceivePayment !== 1) { openTodos++;} else {closeTodos++}

        Cards.push(
            <>

                    <Accordion.Item eventKey={''+x+''}>
                        <Accordion.Header>
                            <p  style={{position: 'absolute',left: '40px',top: '20px'}}>{props.ressponse[x].name}</p>

                            <p  style={{position: 'absolute',right: '40px',top: '20px'}}>Todos:
                                <Badge bg="danger" style={{marginLeft: '10px',marginRight: '10px',width:'30px'}}>{openTodos}</Badge>
                                <Badge bg="success" style={{marginLeft: '10px',marginRight: '10px',width:'30px'}}>{closeTodos}</Badge>
                            </p>
                        </Accordion.Header>
                        <Accordion.Body>
                <Card className={'mb-3'}>
                    <Card.Body>
                        <Card.Title>{props.ressponse[x].name}</Card.Title>
                        <Card.Text style={{marginRight: '20px'}}>
                            <Row>
                                <Col>
                                    <h2>Deutsche Firma</h2>
                                    <Row className={'p-1'}>
                                        <Col>Vorhanden</Col>
                                        {createBadge(props.ressponse[x].germanCompany)}
                                    </Row>
                                    <Row className={' p-1'}>
                                        <Col>Unterlagen</Col>
                                        {createBadge(props.ressponse[x].germanComanyDocuments)}
                                    </Row>
                                </Col>
                                <Col className={'ps-3'}>
                                    <h2>Ausländische Firma</h2>
                                    <Row className={'p-1'}>
                                        <Col>Vorhanden</Col>
                                        {createBadge(props.ressponse[x].foreignCompany)}
                                    </Row>
                                    <Row className={'p-1'}>
                                        <Col>Unterlagen</Col>
                                        {createBadge(props.ressponse[x].foreignCompanyDocuments)}
                                    </Row>
                                </Col>
                                <Col className={'ps-3'}>
                                    <h2>S.R.O</h2>
                                    <Row className={'p-1'}>
                                        <Col>Datenschutzerklärung</Col>
                                        {createBadge(props.ressponse[x].sroPrivacy)}
                                    </Row>
                                    <Row className={'p-1'}>
                                        <Col>Dienstleistungsauftrag</Col>
                                        {createBadge(props.ressponse[x].sroServiceContract)}
                                    </Row>
                                    <Row className={'p-1'}>
                                        <Col>Datenbogen</Col>
                                        {createBadge(props.ressponse[x].sroDataForm)}
                                    </Row>
                                    <Row className={'p-1'}>
                                        <Col>Zahlung angefordert</Col>
                                        {createBadge(props.ressponse[x].sroRequestPayment)}
                                    </Row>
                                    <Row className={'p-1'}>
                                        <Col>Zahlung erhalten</Col>
                                        <Col>{SelectField('sro.receivePayment',props.ressponse[x].sroRecevieDocuments,props.ressponse[x].sroId)}</Col>
                                        {createBadge(props.ressponse[x].sroRecevieDocuments)}
                                    </Row>
                                    <Row className={'p-1'}>
                                        <Col>Notar</Col>
                                        <Col>{SelectField('sro.notar',props.ressponse[x].sroNotar,props.ressponse[x].sroId)}</Col>
                                        {createBadge(props.ressponse[x].sroNotar)}
                                    </Row>
                                {/*    <Row className={'p-1'}>
                                        <Col>Dokumente angefordert</Col>
                                        {createBadge(props.ressponse[x].sroRequestDocuments)}
                                    </Row>*/}
                                    <Row className={'p-1'}>
                                        <Col>Unterlagen</Col>
                                        {createBadge(props.ressponse[x].sroRecevieDocuments)}
                                    </Row>

                                </Col>
                                <Col className={'ps-3'}>
                                    <h2>EWIV</h2>
                                    <Row className={'p-1'}>
                                        <Col>Dienstleistungsvertrag</Col>
                                        {createBadge(props.ressponse[x].ewivServiceContract)}
                                    </Row>
                                    <Row className={'p-1'}>
                                        <Col>Zahlung angefordert</Col>
                                        {createBadge(props.ressponse[x].ewivRequestPayment)}
                                    </Row>
                                    <Row className={'p-1'}>
                                        <Col>Zahlung erhalten</Col>
                                        <Col>{SelectField('ewiv.receivePayment',props.ressponse[x].ewivReceivePayment,props.ressponse[x].ewivId)}</Col>
                                        {createBadge(props.ressponse[x].ewivReceivePayment)}
                                    </Row>
                                    <Row className={'p-1'}>
                                        <Col>Notar</Col>
                                        <Col>{SelectField('ewiv.notar',props.ressponse[x].ewivNotar,props.ressponse[x].ewivId)}</Col>
                                        {createBadge(props.ressponse[x].ewivNotar)}
                                    </Row>
                                    {/*<Row className={'p-1'}>
                                        <Col>Dokumente angefordert</Col>
                                        {createBadge(props.ressponse[x].ewivRequestDocuments)}
                                    </Row>*/}
                                    <Row className={'p-1'}>
                                        <Col>Unterlagen</Col>
                                        {createBadge(props.ressponse[x].ewivReceiveDocuments)}
                                    </Row>

                                </Col>
                            </Row>

                        </Card.Text>

                    </Card.Body>
                </Card>
                        </Accordion.Body>
                    </Accordion.Item>

            </>
        )
    }

    return (
          <>
              <Accordion>
              {Cards}
          </Accordion>

          </>
    )
}


const createBadge = (state:any) => {
    if(state === 1) {
        return (
            <>
                <Col className={'text-right adjustWidth'}><Badge bg="success">&#10003;</Badge><br /></Col>
            </>
        )
    } else {
        return (
            <>
                <Col className={'text-right adjustWidth'}><Badge bg="danger">&#10007;</Badge><br /></Col>
            </>
        )
    }



}
