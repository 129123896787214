import input from './elements/input'
import textarea from './elements/textarea'
import formFile from './elements/formFile'
import select from './elements/select'
import checkbox from './elements/checkbox'
import WYSIWYG from './elements/WYSIWYG'
import header from './elements/header'
import Col from 'react-bootstrap/Col'
import { Row } from 'react-bootstrap'
import col_helper from './elements/helper/col_helper'
import divider from './elements/divider'
import React from 'react'
import description from './elements/description'
import button from './elements/button'
import br from './elements/br'
import image from './elements/image'
import Fetchselect from './elements/Fetchselect'
import holder from './elements/holder'
import formFileBucket from './elements/formFileBucket'
import radio from './elements/radio'
import { IntlMessageValue } from '../component/const/IntlMessage'
import url from './elements/url'
import rs from './elements/rs'
import re from './elements/re'
import sitemap from './elements/siemap'
import imageMerchant from './elements/imageMerchant'
import IconHolder from './elements/IconHolder'
import SyntaxHighlight from './elements/SyntaxHighlight'
import inputGroup from './elements/inputGroup'
import formFileVideo from './elements/formFileVideo'
import LoopSunEditor from "./elements/LoopSunEditor";
import TabMaker from "./elements/tabMaker";
import buttonTabeMaker from "./elements/buttonTabeMaker";
import clear from "./elements/clear";

function renderFormSwitch(
    data: any,
    i: number,
    optionalFuncFirst: any = false,
    optionalFuncSecond: any = false,
    Store: any
) {
    renderLanguage(data[i], Store)

    switch (data[i].formType) {
        case 'input':
            return input(data[i], i, optionalFuncFirst, Store)
        case 'inputGroup':
            return inputGroup(data[i], i, optionalFuncFirst, Store)
        case 'textarea':
            return textarea(data[i], i, optionalFuncSecond, Store)
        case 'formFile':
            return formFile(data[i], Store)
        case 'formFileVideo':
            return formFileVideo(data[i], Store)
        case 'SyntaxHighlight':
            return SyntaxHighlight(data[i], Store)
        case 'formFileBucket':
            return formFileBucket(data[i], Store)
        case 'select':
            return select(data[i], i, Store)
        case 'fetchselect':
            return Fetchselect(data[i])
        case 'checkbox':
            return checkbox(data[i])
        case 'radio':
            return radio(data[i])
        case 'suneditor':
            return WYSIWYG(data[i], i, undefined)
        case 'header':
            return header(data[i])
        case 'image':
            return image(data[i])
        case 'imageMerchant':
            return imageMerchant(data[i])
        case 'description':
            return description(data[i])
        case 'button':
            return button(data[i])
        case 'two_input':
            return (
                <>
                    <Col>{input(data[i].input[0], i, null, Store)}</Col>
                    <Col>{input(data[i].input[1], i, null, Store)}</Col>
                </>
            )
        case 'multiply':
            return (
                <>
                    <Row id={data[i].RowId}>{col_helper(data[i], Store)}</Row>
                </>
            )
        case 'multiplyinRow':
            return <>{col_helper(data[i], Store)}</>
        case 'divider':
            return <>{divider()}</>
        case 'sitemap':
            return <>{sitemap()}</>
        case 'rs':
            return <>{rs()}</>
        case 're':
            return <>{re()}</>
        case 'br':
            return <>{br()}</>
        case 'holder':
            return <>{holder(data[i], i, Store)}</>
        case 'IconHolder':
            return <>{IconHolder(data[i])}</>
        case 'url':
            return url(data[i], i, optionalFuncFirst, Store)
        case 'LoopSunEditor':
            return LoopSunEditor(data[i], i)
        case 'TabMaker':
            return TabMaker(data[i])
        case 'buttonTabeMaker':
            return buttonTabeMaker(data[i])
        case 'clear':
            return clear()
    }
}

export default renderFormSwitch

/**
 * Helper für den FormBuilder um Language abzufangen und auszutauschen
 * @param {object} data beinhaltet Daten des Feldes
 */
const renderLanguage = (data: any, Store: any) => {
    data.placeholder = helperLang(data.placeholder, Store)

    data.value = helperLang(data.value, Store)

    data.label = helperLang(data.label, Store)

    return data
}

const helperLang = (Key: any, Store: any) => {
    let langKey: string[]
    let LangKeyValue: string
    let LangValue: string
    if (typeof Key === 'string' && Key !== undefined) {
        if (Key.includes('lang')) {
            langKey = Key.split('lang[')
            if (langKey[1] !== undefined) {
                LangKeyValue = langKey[1].split(']')[0]
            } else {
                LangKeyValue = ''
            }
            LangValue = IntlMessageValue('de', LangKeyValue, Store)
        } else {
            return Key
        }
        return LangValue
    } else {
        return Key
    }
}
